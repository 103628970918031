import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { PricingCard } from '../components/pricing/price-list-options'
import { GetInTouch } from '../components/index/get-in-touch';
import StringTable from '../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.pricing.PricingPage;
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  priceOptions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: [[40, 40, 40, 40]],
  },
}));
//------------------------------------------------------------------------------
export function PricingPage(props)
{
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <div className={classes.priceOptions}>
        <PricingCard planName={STRINGS.titleCard1} planPrice={'$20'} description={[STRINGS.contentC1L1,STRINGS.contentC1L2,STRINGS.contentC1L3]} additionaInfo={[STRINGS.contentC1L4,STRINGS.contentC1L5]}/>
        <PricingCard planName={STRINGS.titleCard2} planPrice={'$60'} description={[STRINGS.contentC2L1,STRINGS.contentC2L2,STRINGS.contentC2L3]} additionaInfo={[STRINGS.contentC2L4,STRINGS.contentC2L5]}/>
        <PricingCard planName={STRINGS.titleCard3} planPrice={'$200'} description={[STRINGS.contentC3L1,STRINGS.contentC3L2,STRINGS.contentC3L3]} additionaInfo={[STRINGS.contentC3L4,STRINGS.contentC3L5]}/>
      </div>
      <div><GetInTouch id={'contactus'}/></div>
    </div>
  );
}
//------------------------------------------------------------------------------
export default PricingPage;
//******************************************************************************
