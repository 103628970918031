import React from 'react';
import { Card, CardContent, CardActions, CardHeader, Button, Typography, Divider, makeStyles } from '@material-ui/core';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.pricing.PricingPage;
//******************************************************************************

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 12,
    minWidth: 300,
    textAlign: 'center',
  },
  header: {
    textAlign: 'center',
    spacing: 10,
  },
  list: {
    padding: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const PricingCard = React.memo(function PricingCard(props) {
  const {planName, planPrice, description, additionaInfo} = props;
  const descriptionItems = [];
  for(let i = 0; i < description.length; i++)
  {
    const item = description[i];
    descriptionItems.push(
      <Typography key={i} allign={'center'}>{item}</Typography>
    );
  }
  const additionalInfoItems = [];
  for(let i = 0; i < description.length; i++)
  {
    const item = additionaInfo[i];
    additionalInfoItems.push(
      <Typography key={i} allign={'center'}>{item}</Typography>
    );
  }
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title={planName} className={classes.header} />
      <Divider variant="middle" />
      <CardContent>
        <Typography variant="h3" align="center">
          {planPrice}
        </Typography>
        <div className={classes.list}>
          {descriptionItems}
        </div>
      </CardContent>
      <Divider variant="middle" />
      <CardActions className={classes.action}>
        <Button variant="contained" color="primary" className={classes.button}>
        {STRINGS.buttonName}
        </Button>
      </CardActions>
      <Divider variant="middle" />
      <div className={classes.list}>
          {additionalInfoItems}
        </div>
    </Card>
  );
});

export default PricingCard